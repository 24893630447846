import { dispatchCommand } from '~apis/commands.api';

import type { CommandResponses } from '~apis/commands.api';
import type { MarketingCommandsNl } from '~models/commands-nl';
import { useProspectId } from '~components/ProspectProvider';

type SendCommand = <T extends keyof MarketingCommandsNl>(
  commandType: T,
  command: MarketingCommandsNl[T],
  meta?: { [key: string]: string },
  callBack?: (() => void) | undefined,
) => Promise<CommandResponses<T>[T]>;

const API_BASE_URL = process.env.GATSBY_APP_COMMAND_URL_NL as string;

export function useCommandsNl(): { sendCommand: SendCommand } {
  const { prospectId } = useProspectId();
  const sendCommand: SendCommand = async (commandType, command, meta, callBack) => {
    const endPoint = commandType === 'usePartner' ? `/${prospectId}/wizard` : `/${prospectId}/marketing`;
    try {
      const { response } = (await dispatchCommand({
        prospectId,
        commandType,
        command,
        baseUrl: API_BASE_URL,
        endPoint,
        meta,
      })) as any;
      callBack && callBack();
      return response;
    } catch (error) {
      return console.error(error);
    }
  };

  return { sendCommand };
}
