import { Country } from '~models/country';

export enum GTMId {
  nl = 'GTM-M82HZ3T',
  de = 'GTM-MBVRS38',
  fr = 'GTM-TPXPLKJ',
}

export enum locales {
  nl = 'nl-NL',
  de = 'de-DE',
  fr = 'fr-FR',
}

export const COUNTRY = (process.env.GATSBY_APP_COUNTRY as Country) || 'nl';

export const SITE_URL = `https://www.insify.${COUNTRY}`;

export const GTM_URL = `https://www.googletagmanager.com/gtm.js?id=${GTMId[COUNTRY]}`;

export const internalLinkRegex = new RegExp(`^https:\/\/(www\.)?insify.${COUNTRY}\/?`, 'i');
export const funnelLinkRegex = /^https:\/\/(www\.)?((dev|staging)\.)?(shop|aov|mutns)\.insify.(nl|de|fr)(.*)/i;

export type BlogCategory = {
  contentfulName: string;
  name: string;
  path: string;
};
