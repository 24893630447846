import { useMemo } from 'react';

import { funnelLinkRegex } from '~globals';
import { usePartnerParams } from '~components/PartnerParamsProvider';
import { useMarketingParams } from '~components/MarketingParamsProvider';
import { useProspectId } from '~components/ProspectProvider';

import type { MarketingParams, PartnerParams } from '@insify/ui-utils';

type UseFunnelUrlReturn = {
  formatFunnelLink: (link: string) => string;
};

export const getFunnelQuery = (
  marketingParams?: MarketingParams,
  prospectId?: string,
  partnerParams?: PartnerParams,
): string => {
  const prospectQuery = prospectId && `prospectId=${prospectId}`;
  const utmQuery =
    marketingParams &&
    marketingParams.utm &&
    `utm_source=${marketingParams.utm.source}&utm_medium=${marketingParams.utm.medium}&utm_campaign=${marketingParams.utm.campaign}&utm_term=${marketingParams.utm.term}`;
  const partnerQuery =
    partnerParams?.redirectingPartnerName && `redirectingPartnerName=${partnerParams.redirectingPartnerName}`;
  return `?${[utmQuery, prospectQuery, partnerQuery].filter(Boolean).join('&')}`;
};

export const useFunnelUrl = (): UseFunnelUrlReturn => {
  const { prospectId } = useProspectId();
  const { marketingParams } = useMarketingParams();
  const { partnerParams } = usePartnerParams();

  const funnelQuery: string = useMemo(
    () => getFunnelQuery(marketingParams, prospectId, partnerParams),
    [marketingParams, prospectId, partnerParams],
  );

  /**
   * Automatically add prospectId and marketing params to url if it's a funnel URL (prod, dev, staging etc.)
   */
  const formatFunnelLink = (link: string): string => {
    try {
      return funnelLinkRegex.test(link) ? `${new URL(link).origin}${funnelQuery}` : link;
    } catch (err) {
      return link;
    }
  };

  return { formatFunnelLink };
};
